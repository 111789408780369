import * as Sentry from "@sentry/react";
import { createBrowserRouter, Navigate, Outlet, RouteObject } from "react-router-dom";
import { HttpMethod, request, Urls } from "./api";
import { AuthenticatedRoute } from "./components/auth";
import { ApplicationFrame, AuthFrame } from "./components/frame";
import { loader as dayMenuLoader } from "./pages/day-menu.lib.ts";
import { DayMenuPage } from "./pages/day-menu.tsx";
import { ForgotPassword } from "./pages/forgot-password";
import { AddCollections } from "./pages/onboarding-add-collections";
import { onboardingCollectionsLoader } from "./pages/onboarding.lib";
import { ResetPassword } from "./pages/password-reset";
import { loader as planLoader } from "./pages/plan.lib.ts";
import { PlanPage } from "./pages/plan.tsx";
import { loader as recipeSwapLoader } from "./pages/recipe-swap.lib.ts";
import { RecipeSwap } from "./pages/recipe-swap.tsx";
import { Root } from "./pages/root.tsx";
import { Signin } from "./pages/signin.tsx";
import { Signup } from "./pages/signup.tsx";
import { Page, User } from "./types.ts";

async function getAppSession(): Promise<User | null> {
  const resp = await request(HttpMethod.GET, Urls.CurrentSession);

  if (resp.status !== 200) {
    return null;
  }

  const contents = await resp.json();

  return {
    displayName: contents.data.user.display,
    email: contents.data.user.email,
    id: contents.data.user.id,
    // TODO: This needs to be more robust
    selected_household: contents.data.user.households[0],
    households: contents.data.user.households,
    onboardingStatus: contents.data.user["onboarding_status"],
    isAnonymous: false,
  };
}

export const routes: RouteObject[] = [
  {
    path: "/",
    id: "root",
    loader: getAppSession,
    element: (
      <Sentry.ErrorBoundary showDialog>
        <Root />,
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        element: (
          <AuthenticatedRoute>
            <Outlet />
          </AuthenticatedRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate to={Page.Plan} />,
          },
          {
            path: "onboarding",
            children: [
              {
                path: "add-collections",
                // TODO: Add page here
                element: <AddCollections />,
                loader: async () => {
                  return onboardingCollectionsLoader();
                },
              },
            ],
          },

          {
            element: (
              <ApplicationFrame>
                <Outlet />
              </ApplicationFrame>
            ),
            children: [
              {
                id: "app:plan",
                path: "plan",
                loader: planLoader,
                element: <PlanPage />,
              },
              {
                path: "menu/:menuDate",
                loader: dayMenuLoader,
                element: <DayMenuPage />,
              },
              {
                path: "schedule/:scheduledRecipeId/swap",
                loader: recipeSwapLoader,
                element: <RecipeSwap />,
              },
            ],
          },
        ],
      },
      {
        element: (
          <AuthFrame>
            <Outlet />
          </AuthFrame>
        ),
        children: [
          {
            path: "signin",
            element: <Signin />,
          },
          {
            path: "signup",
            element: <Signup />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "password-reset/:key",
            element: <ResetPassword />,
          },
        ],
      },
    ],
  },
];

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter(routes);
