import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useContext } from "react";
import { Navigate } from "react-router";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { APIError, assertIsAPIError, HttpMethod, request, Urls } from "../api";
import { FormErrorMessage, FormikSubmitButton, LabeledTextInput } from "../components/fieldset";
import { AuthPageHeader } from "../components/frame";
import { Link } from "../components/link";
import { SessionContext } from "../session-context";
import { Page, User } from "../types";
import { usePageTitle } from "../utils";

async function signin(data: { email: string; password: string }): Promise<User> {
  const resp = await request(HttpMethod.POST, Urls.Signin, data);
  const contents = await resp.json();

  if (resp.status !== 200) {
    throw new APIError({ kind: "UnknownError", status: resp.status });
  }

  return {
    displayName: contents.data.user.display,
    email: contents.data.user.email,
    id: contents.data.user.id,
    // TODO: This needs to be more robust
    selected_household: contents.data.user.households[0],
    households: contents.data.user.households,
    onboardingStatus: contents.data.user["onboarding-status"],
    isAnonymous: false,
  };
}

interface SigninFormValues {
  email: string;
  password: string;
}

const initialValues: SigninFormValues = { email: "", password: "" };

async function onSubmit(values: SigninFormValues, actions: FormikHelpers<SigninFormValues>) {
  try {
    const user = await signin(values);
    return user;
  } catch (error) {
    // If we get an API Error we can handle that here
    assertIsAPIError(error);

    // Field errors should match up by name if there are any
    error.errors?.forEach(({ message, param }) => {
      actions.setFieldError(param, message);
    });

    // We set the global form error state to display a top level error to the user.
    // This might not be friendly, so we might not want to do this.
    actions.setStatus(error);
    return undefined;
  }
}

const SigninSchema = toFormikValidationSchema(
  z
    .object({
      email: z
        .string({ required_error: "Email is required" })
        .email("A valid email is required")
        .min(1, "Email is required"),
      password: z.string({ required_error: "Password is required" }).min(1, "Password is required"),
    })
    .required({ email: true, password: true }),
);

function SigninForm(props: FormikProps<SigninFormValues>) {
  return (
    <Form className="space-y-6">
      {props.status && <FormErrorMessage>{props.status.message}</FormErrorMessage>}

      <Field name="email">
        {({ field, meta }: FieldProps) => (
          <LabeledTextInput
            label="Email"
            inputProps={{ ...field, type: "email", autoComplete: "email" }}
            error={meta.touched && meta.error ? meta.error : undefined}
          />
        )}
      </Field>

      <Field name="password">
        {({ field, meta }: FieldProps) => (
          <LabeledTextInput
            label="Password"
            inputProps={{ ...field, type: "password", autoComplete: "new-password" }}
            error={meta.touched && meta.error ? meta.error : undefined}
          />
        )}
      </Field>

      <div className="flex justify-end">
        <p className="mt-2 text-sm leading-6 text-zinc-500">
          <Link to={Page.ForgotPassword}>Forgot Password?</Link>
        </p>
      </div>

      <FormikSubmitButton
        title="Sign in"
        isSubmitting={props.isSubmitting}
      />
    </Form>
  );
}

export function Signin() {
  usePageTitle("Sign in");
  const session = useContext(SessionContext);

  if (session.session.user) {
    return <Navigate to={Page.Plan} />;
  }

  return (
    <>
      <AuthPageHeader
        heading="Sign in to MealsMatter"
        extra=<p className="mt-2 text-sm leading-6 text-zinc-500">
          Not a member? <Link to={Page.Signup}>Start a 14 day free trial</Link>
        </p>
      />

      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={SigninSchema}
          onSubmit={async (values: SigninFormValues, actions: FormikHelpers<SigninFormValues>) => {
            const user: User | undefined = await onSubmit(values, actions);

            session.setSession((s) => {
              return { ...s, user: user };
            });
          }}
        >
          {/*Note this is a function not a component*/}
          {SigninForm}
        </Formik>
      </div>
    </>
  );
}
