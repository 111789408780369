import "./instrument";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./app.css";
import posthog from "posthog-js";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

const elem = (
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);

createRoot(document.getElementById("root")!).render(elem);

posthog.capture("application load");
