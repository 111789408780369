import { Params } from "react-router-dom";
import { HttpMethod, request, Urls } from "../api.ts";
import { DayMenu, DayMenuSchema } from "./plan.lib.ts";

export async function loader({
  params,
}: {
  params: Params<"menuDate">;
}): Promise<DayMenu | Response> {
  const url = Urls.DayMenu.replace("{menuDate}", params.menuDate!);
  const resp = await request(HttpMethod.GET, url);

  if (resp.status !== 200) {
    return resp;
  }

  const payload = await resp.json();
  return DayMenuSchema.parse(payload.data);
}

/**
 * Do an API call to remove a ScheduledRecipe.
 */
export async function removeRecipe(srId: string) {
  const url = Urls.RemoveScheduledRecipe.replace("{scheduledRecipeId}", srId);
  return await request(HttpMethod.POST, url, null);
}
