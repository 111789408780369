import { createContext, SetStateAction } from "react";
import { HttpMethod, request, Urls } from "./api";
import { Session, SessionOptions } from "./types";

export async function getConfig(): Promise<SessionOptions | null> {
  /** This API call serves two purposes:
   *      1) is to ensure that the API is up and running,
   *      2) ensure that we have a CSRF cookie set
   */

  const resp = await request(HttpMethod.GET, Urls.Config);
  const payload = await resp.json();
  return {
    authentication: {
      authenticationMethod: payload.data.account.authentication_method,
      canSignup: payload.data.account.is_open_for_signup,
    },
  };
}

export type SessionContextType = {
  session: Session;
  setSession: React.Dispatch<React.SetStateAction<Session>>;
};

export const defaultSession: Session = {
  options: {
    authentication: {
      authenticationMethod: "email",
      canSignup: true,
    },
  },
  user: undefined,
};

export const defaultSessionContext = {
  session: defaultSession,
  // eslint-disable-next-line
  setSession: (_: SetStateAction<Session>) => {},
};

export const SessionContext = createContext<SessionContextType>(defaultSessionContext);
