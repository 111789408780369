import { Params } from "react-router-dom";
import { z } from "zod";
import { HttpMethod, request, Urls } from "../api.ts";
import { ScheduledRecipeSchema, ScheduledRecipeType } from "./plan.lib.ts";

export const SwapTargetSchema = ScheduledRecipeSchema.merge(
  z.object({
    mealTitle: z.string(),
    mealDate: z.string(),
    mealTime: z.string(),
  }),
);

/** A scheduled recipe that also includes information about the meal. */
export type SwapTargetType = z.infer<typeof SwapTargetSchema>;

export const RecipeSchema = z.object({
  id: z.string(),
  title: z.string(),
  courses: z.array(z.string()),
  tags: z.array(z.string()),
  totalTime: z.string().nullable(),
  imageUrl: z.string(),
});

/** A recipe shown in a recipe selection list. */
export type RecipeType = z.infer<typeof RecipeSchema>;

export const SwapRecipeResponseSchema = z.object({
  swapTarget: SwapTargetSchema,
  recipes: z.array(RecipeSchema),
});

/** The response data for a swap recipe selector. */
export type SwapRecipeResponseType = z.infer<typeof SwapRecipeResponseSchema>;

/** Loader for the SwapRecipe page. */
export async function loader({
  params,
}: {
  params: Params<"scheduledRecipeId">;
}): Promise<SwapRecipeResponseType | Response> {
  const url = Urls.SwapScheduledRecipe.replace("{scheduledRecipeId}", params.scheduledRecipeId!);
  const resp = await request(HttpMethod.GET, url);
  if (resp.status !== 200) {
    return resp;
  }

  const payload = await resp.json();
  return SwapRecipeResponseSchema.parse(payload.data);
}

/** Make an API call swap out a scheduled recipe with another recipe. */
export async function swapRecipe(recipeId: string, target: ScheduledRecipeType) {
  const url = Urls.SwapScheduledRecipe.replace("{scheduledRecipeId}", target.id);
  return await request(HttpMethod.POST, url, { recipeId: recipeId });
}
