import clsx from "clsx";
import { Avatar } from "./avatar";

type LogoSize = "size-8" | "size-12" | "size-20";

type LogoProps = {
  includeName?: boolean;
  className?: string;
  size: LogoSize;
};

export function Logo(
  { size, className, includeName }: LogoProps = {
    size: "size-8",
    className: "",
    includeName: false,
  },
) {
  const textSize = {
    "size-8": "text-sm",
    "size-12": "text-lg",
    "size-20": "text-2xl",
  }[size];

  const withText = (
    <div className="flex flex-row">
      <Avatar
        className={clsx(size, className)}
        src="/images/favicon.svg"
      />
      <span className={clsx(textSize, "ml-3 self-center")}>MealsMatter</span>
    </div>
  );

  const withoutText = (
    <Avatar
      className={clsx(size, className)}
      src="/images/favicon.svg"
    />
  );
  return includeName ? withText : withoutText;
}
