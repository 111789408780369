export type Theme = "system" | "light" | "dark";

export type SessionOptions = {
  authentication: {
    authenticationMethod: "email";
    canSignup: boolean;
  };
};

export type Session = {
  options: SessionOptions;
  user?: User;
};

export type SessionContextType = {
  session: Session;
  setSession: React.Dispatch<React.SetStateAction<Session>>;
};

export enum Page {
  Signin = "/signin",
  Signup = "/signup",
  ForgotPassword = "/forgot-password",
  Plan = "/plan",
  OnboardingAddCollections = "/onboarding/add-collections",
}

export type Household = {
  id: string;
  title: string;
};

export type User = {
  displayName: string;
  email: string;
  id: string;
  selected_household?: Household;
  households: Household[];
  onboardingStatus: "done" | "create-home" | "start-cookbook";
  // The user context requires us to pass a default that should never be used in the application
  // UNLESS there is no parent provider in the tree. This allows us to indicate that the user was
  // not fetched from the backend but is in fact anonymous. It's better than being undefined
  // See docs here: https://reactjs.org/docs/context.html#reactcreatecontext
  isAnonymous: boolean;
};
