import { ArrowPathIcon, PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { Link, useLoaderData, useRevalidator } from "react-router-dom";
import { Alert, AlertActions, AlertDescription, AlertTitle } from "../components/alert.tsx";
import { Button } from "../components/button.tsx";
import { Heading, Subheading } from "../components/heading.tsx";
import { Spinner } from "../components/spinner.tsx";
import { usePageTitle } from "../utils.ts";
import { removeRecipe } from "./day-menu.lib.ts";
import { DayMenu, MealType, ScheduledRecipeType } from "./plan.lib.ts";
import { RecipeCourses } from "./recipe.tsx";

function Meal({ meal }: { meal: MealType }) {
  return (
    <div className="mb-8">
      <div className="mb-2 flex flex-row items-baseline justify-between">
        <Subheading
          level={3}
          className="uppercase tracking-wide"
        >
          {meal.title}
        </Subheading>
        <Subheading level={3}>{meal.timeOfDay || ""}</Subheading>
      </div>
      <div className="flex flex-col gap-2">
        {meal.recipes.map((recipe) => (
          <Recipe
            key={recipe.id}
            recipe={recipe}
          />
        ))}
      </div>
      <Link
        to=""
        className="mt-4 text-sm block border-2 border-dotted border-theme-normal/50 p-2 text-center font-semibold text-theme-normal/50"
      >
        <div>
          <PlusIcon className="inline w-4" /> Add to {meal.title}
        </div>
      </Link>
    </div>
  );
}

/**
 * Display recipe details.
 *
 * The layout breaks at `sm`.
 */
function Recipe({ recipe }: { recipe: ScheduledRecipeType }) {
  const revalidator = useRevalidator();
  const [removalAlertIsOpen, setRemovalAlertIsOpen] = useState(false);
  const [removalInProgress, setRemovalInProgress] = useState(false);

  async function handleRemove() {
    setRemovalInProgress(true);
    await removeRecipe(recipe.id);
    revalidator.revalidate();
    setRemovalAlertIsOpen(false);
    setRemovalInProgress(false);
  }

  return (
    <div className="mb-4 flex gap-4 flex-row items-start">
      {/* Image header */}
      {recipe.imageUrl && (
        <img
          className="rounded-md object-cover w-20 aspect-[4/3] sm:w-40"
          src={recipe.imageUrl}
        />
      )}
      {/* Recipe details box */}
      <div className="flex grow flex-col sm:flex-row items-start justify-between gap-2">
        {/* Recipe attributes */}
        <div className="flex grow flex-col gap-1">
          <div className="text-lg font-bold text-theme-muted">{recipe.title}</div>
          <RecipeCourses courses={[recipe.course]} />
          {recipe.totalTime !== null && (
            <div>
              <span className="text-theme-muted">Cook time:</span> {recipe.totalTime}
            </div>
          )}
          {recipe.servings !== null && (
            <div>
              <span className="text-theme-muted">Servings:</span> {recipe.servings}
            </div>
          )}
        </div>
        {/* Buttons */}
        <div className="flex flex-row gap-x-2 gap-y-1">
          <Button
            color="light"
            onClick={() => setRemovalAlertIsOpen(true)}
          >
            <TrashIcon /> Remove
          </Button>
          <Alert
            open={removalAlertIsOpen}
            onClose={setRemovalAlertIsOpen}
          >
            <AlertTitle>Are you sure you want to take this recipe off the menu?</AlertTitle>
            <AlertDescription>The recipe will be removed from the schedule.</AlertDescription>
            <AlertActions>
              <Button
                plain
                onClick={() => setRemovalAlertIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={handleRemove}
                disabled={removalInProgress} // Disabled during removal.
              >
                {removalInProgress && <Spinner />}
                Remove
              </Button>
            </AlertActions>
          </Alert>
          <Button
            color="light"
            href={`/schedule/${recipe.id}/swap`}
          >
            <ArrowPathIcon /> Swap
          </Button>
        </div>
      </div>
    </div>
  );
}

export function DayMenuPage() {
  const day = useLoaderData() as DayMenu;

  const dayTitle = `${day.title}’s Menu`;
  usePageTitle(dayTitle);

  return (
    <div>
      {/* Title */}
      <div className="mb-4 mt-10 flex flex-row items-baseline justify-between">
        <Heading className="text-theme-normal">{dayTitle}</Heading>
        <Subheading className="text-2xl font-semibold text-theme-low-contrast">
          {day.subTitle}
        </Subheading>
      </div>
      {/* Meals */}
      {day.meals.map((meal) => (
        <Meal
          key={meal.id}
          meal={meal}
        />
      ))}
    </div>
  );
}
