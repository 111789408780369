import { createContext } from "react";
import { User } from "./types";

export const UserContext = createContext<User>({
  displayName: "",
  email: "",
  id: "",
  households: [],
  onboardingStatus: "done",
  isAnonymous: true,
});
