import { useState } from "react";
import { Outlet, useRouteLoaderData } from "react-router";
import { defaultSession, SessionContext } from "../session-context";
import { ThemeProvider } from "../theme.tsx";
import { Session, User } from "../types.ts";

export const Root = () => {
  const user = useRouteLoaderData("root") as User | undefined;
  const sess: Session = {
    ...defaultSession,
    user: user,
  };

  const [session, setSession] = useState(sess);

  return (
    <ThemeProvider>
      <SessionContext.Provider value={{ session, setSession }}>
        <Outlet />
      </SessionContext.Provider>
    </ThemeProvider>
  );
};
