/**
 * TODO: Update this component to use your client-side framework's link
 * component. We've provided examples of how to do this for Next.js, Remix, and
 * Inertia.js in the Catalyst documentation:
 *
 * https://catalyst.tailwindui.com/docs#client-side-router-integration
 */

import * as Headless from "@headlessui/react";
import React, { forwardRef } from "react";
import { Link as RRLink } from "react-router-dom";
import { Page } from "../types";

type LinkProps = {
  to?: Page;
  reloadDocument?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
};

export const Link = forwardRef(function Link(
  props: LinkProps & React.ComponentPropsWithoutRef<"a">,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const where: string = props.to || props.href || document.URL;

  return (
    <Headless.DataInteractive>
      <RRLink
        className="font-semibold text-gray-600 hover:text-gray-500"
        {...props}
        to={where}
        ref={ref}
        state={props.state}
      />
    </Headless.DataInteractive>
  );
});
