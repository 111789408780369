import * as icons from "@heroicons/react/20/solid";
import { HttpMethod, request, Urls } from "../api";
import { Page } from "../types";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "./dropdown";
import { Link } from "./link";
import { Logo } from "./logo";
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from "./navbar";
import { Sidebar, SidebarBody, SidebarItem, SidebarSection, SidebarSpacer } from "./sidebar";
import { ThemeSelector } from "./theme-selector.tsx";

const navItems = [{ key: "plan", url: Page.Plan, label: "Plan" }];

function AccountDropdown() {
  return (
    <Dropdown>
      <DropdownButton
        as={NavbarItem}
        aria-label="Account Menu"
      >
        <icons.UserIcon />
      </DropdownButton>
      <DropdownMenu>
        <DropdownItem href={Page.Signin}>
          <icons.ArrowRightStartOnRectangleIcon />
          <DropdownLabel
            onClick={async () => {
              await request(HttpMethod.POST, Urls.Signout);
              window.location.reload();
            }}
          >
            Sign out
          </DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export function Nav() {
  return (
    <Navbar>
      <div className="max-lg:hidden">
        <Link to={Page.Plan}>
          <Logo
            size="size-12"
            includeName={true}
          />
        </Link>
      </div>
      <NavbarSection className="max-lg:hidden">
        {navItems.map(({ key, url, label }) => {
          return (
            <NavbarItem
              href={url}
              key={key}
            >
              <span className="uppercase tracking-wide">{label}</span>
            </NavbarItem>
          );
        })}
      </NavbarSection>
      <NavbarSpacer />
      <NavbarSection className="max-lg:hidden">
        <ThemeSelector />
      </NavbarSection>
      <AccountDropdown />
    </Navbar>
  );
}

export function MobileNav() {
  return (
    <Sidebar>
      <SidebarBody>
        <SidebarSection>
          <Link to={Page.Plan}>
            <Logo
              size="size-12"
              includeName={true}
            />
          </Link>
          {navItems.map(({ key, url, label }) => {
            return (
              <SidebarItem
                href={url}
                key={key}
              >
                <span className="uppercase">{label}</span>
              </SidebarItem>
            );
          })}
        </SidebarSection>
        <SidebarSpacer />
        <SidebarSection>
          <ThemeSelector />
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
}
