import { ArrowLeftIcon, CheckIcon } from "@heroicons/react/20/solid";
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";
import { Button } from "../components/button.tsx";
import { Heading } from "../components/heading.tsx";
import { usePageTitle } from "../utils";
import {
  RecipeType,
  swapRecipe,
  SwapRecipeResponseType,
  SwapTargetType,
} from "./recipe-swap.lib.ts";
import { RecipeCourses, RecipeTags } from "./recipe.tsx";

/**
 * Show details about the scheduled recipe that is being swapped out.
 */
function TargetDetails({ target }: { target: SwapTargetType }) {
  return (
    <div>
      <div>
        Select a replacement for <span className="font-semibold">{target.title}</span>, currently
        scheduled as a {target.course} for {target.mealTitle} on {target.mealDate}, at{" "}
        {target.mealTime}.
      </div>
    </div>
  );
}

/**
 * Show details about a possible replacement recipe.
 */
function Recipe({
  recipe,
  clickLabel,
  clickHandler,
}: {
  recipe: RecipeType;
  clickLabel: string;
  clickHandler: () => void;
}) {
  return (
    <div className="flex flex-row gap-4 items-start">
      {/* Image header */}
      {recipe.imageUrl && (
        <img
          className="rounded-md object-cover w-20 sm:aspect-[4/3] sm:w-40"
          src={recipe.imageUrl}
        />
      )}
      {/* Recipe details box */}
      <div className="flex grow flex-col sm:flex-row items-start justify-between gap-2">
        {/* Recipe attributes */}
        <div className="flex grow flex-col gap-1">
          <div className="text-lg font-bold text-theme-muted">{recipe.title}</div>
          <RecipeCourses courses={recipe.courses} />
          <RecipeTags tags={recipe.tags} />
        </div>
        {/* Buttons */}
        <div className="flex flex-row gap-x-2 justify-end text-nowrap">
          <Button
            color="light"
            onClick={clickHandler}
          >
            <CheckIcon /> {clickLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}

export function RecipeSwap() {
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const { swapTarget, recipes } = useLoaderData() as SwapRecipeResponseType;

  usePageTitle("Swap Recipe");

  async function handleSwapRecipe(recipeId: string) {
    await swapRecipe(recipeId, swapTarget);
    revalidator.revalidate();
    navigate(-1);
  }

  return (
    <div>
      <div className="mb-4 flex flex-row justify-between">
        <Heading>Swap Recipe</Heading>
        <Button
          color="light"
          onClick={() => navigate(-1)}
        >
          <ArrowLeftIcon /> Go Back
        </Button>
      </div>
      <div className="mb-4 rounded-lg shadow bg-theme-muted">
        <div className="p-4">
          <TargetDetails target={swapTarget} />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {recipes.map((r) => (
          <Recipe
            key={r.id}
            recipe={r}
            clickLabel="Choose Recipe"
            clickHandler={async () => {
              handleSwapRecipe(r.id);
            }}
          />
        ))}
      </div>
    </div>
  );
}
