import { FormikHelpers } from "formik";
import { AUTH_BASE_URL, HttpMethod, request } from "../api";

export interface ForgotPasswordValues {
  email: string;
}

export async function submitChangePassword(
  values: ForgotPasswordValues,
  actions: FormikHelpers<ForgotPasswordValues>,
) {
  const resp = await request(HttpMethod.POST, AUTH_BASE_URL + "/auth/password/request", {
    email: values.email,
  });

  if (resp.status !== 200) {
    actions.setStatus(
      new Error(
        "A problem happened when submitting your request. Check your email address and try again.",
      ),
    );
  } else {
    actions.setStatus("Check your email and click the link");
  }
}
