import { FormikHelpers } from "formik";
import { AUTH_BASE_URL, HttpMethod, request } from "../api";

export interface PasswordResetValues {
  key: string;
  password: string;
}

export async function submitPasswordReset(
  values: PasswordResetValues,
  actions: FormikHelpers<PasswordResetValues>,
): Promise<boolean> {
  const resp = await request(HttpMethod.POST, AUTH_BASE_URL + "/auth/password/reset", {
    key: values.key,
    password: values.password,
  });

  // Allauth returns a 401 with the flows
  if (resp.status !== 401) {
    actions.setStatus(new Error("A problem happened when submitting your request."));
    return false;
  } else {
    return true;
  }
}
