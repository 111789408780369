import { ComputerDesktopIcon, MoonIcon, SunIcon } from "@heroicons/react/20/solid";
import { useContext } from "react";
import { ThemeContext } from "../theme.tsx";

/**
 * A theme selector.
 *
 * This cycles between dark, light, and system themes.
 */
export function ThemeSelector() {
  const { theme, rotate } = useContext(ThemeContext);

  const getIcon = () => {
    switch (theme) {
      case "light":
        return <SunIcon />;
      case "dark":
        return <MoonIcon />;
      case "system":
        return <ComputerDesktopIcon />;
    }
  };

  return (
    <div
      className="size-5 text-theme-low-contrast"
      onClick={rotate}
    >
      {getIcon()}
    </div>
  );
}
