import { z } from "zod";
import { BACKEND_URL, HttpMethod, request } from "../api";

export const OnboardingCollectionSchema = z.object({
  id: z.string(),
  title: z.string(),
  image_url: z.string(),
});

export type OnboardingCollection = z.infer<typeof OnboardingCollectionSchema>;

export async function onboardingCollectionsLoader() {
  const resp = await request(HttpMethod.GET, BACKEND_URL + "/api/onboarding/collections");

  if (resp.status !== 200) {
    return resp;
  }

  const payload = await resp.json();
  return z.array(OnboardingCollectionSchema).parse(payload.data);
}

export const OnboardingCollectionFollowSchema = z.object({
  id: z.string(),
});

export type OnboardingCollectionFollow = z.infer<typeof OnboardingCollectionFollowSchema>;

/**
 * Do an API call to start following a collection.
 */
export async function followCollection(
  household: string,
  collection: string,
  toggle: boolean = true,
): Promise<Response> {
  return await request(
    HttpMethod.POST,
    `${BACKEND_URL}/api/cookbook/follow-collection/${collection}?toggle=${toggle}`,
    null,
    { household },
  );
}
