import { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router";
import { SessionContext } from "../session-context";
import { Page } from "../types";
import { UserContext } from "../user-context";

export function AuthenticatedRoute({ children }: PropsWithChildren) {
  const { session } = useContext(SessionContext);

  if (!session.user || session.user.isAnonymous) {
    return <Navigate to={Page.Signin} />;
  }

  return <UserContext.Provider value={session.user}>{children}</UserContext.Provider>;
}
