import { createContext, useEffect, useState } from "react";
import { Theme } from "./types";

export type ThemeContextType = {
  theme: Theme;
  rotate: () => void;
};

const initialThemeContext: ThemeContextType = {
  theme: "system",
  rotate: () => {},
};

export const ThemeContext = createContext<ThemeContextType>(initialThemeContext);

/**
 * Look up the theme setting that is saved in local storage, if present.
 */
function getSavedTheme(): Theme {
  const saved = localStorage.getItem("theme");
  if (saved === "light" || saved === "dark") {
    return saved;
  }
  return "system";
}

/**
 * Apply a theme value to the page.
 *
 * For this to work, tailwind must be configured with the `selector` strategy:
 * https://tailwindcss.com/docs/dark-mode#supporting-system-preference-and-manual-selection
 *
 * For the custom theme (see `src/index.css` and `tailwind.config.js`), we need to set either
 * `theme-dark` or `theme-light`.
 *
 * There are some existing styles (for example, from the Catalyst components) that use the `dark:`
 * modifier. For this reason, we also must add `dark` alongside `theme-dark`.
 */
function applyTheme(theme: Theme) {
  const isDark =
    theme === "dark" ||
    (theme === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches);

  if (isDark) {
    document.documentElement.classList.remove("theme-light");
    document.documentElement.classList.add("theme-dark", "dark");
  } else {
    document.documentElement.classList.remove("theme-dark", "dark");
    document.documentElement.classList.add("theme-light");
  }
}

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState(getSavedTheme);

  useEffect(() => {
    localStorage.setItem("theme", theme);
    applyTheme(theme);
  }, [theme]);

  const rotate = () => {
    switch (theme) {
      case "light":
        setTheme("dark");
        break;
      case "dark":
        setTheme("system");
        break;
      case "system":
        setTheme("light");
        break;
    }
  };

  return <ThemeContext.Provider value={{ theme, rotate }}>{children}</ThemeContext.Provider>;
}
