import { PencilIcon } from "@heroicons/react/20/solid";
import { Link, useLoaderData } from "react-router-dom";
import { Button } from "../components/button.tsx";
import { usePageTitle } from "../utils";
import { DayMenu, MealType, ScheduledRecipeType } from "./plan.lib";

function Day({ day }: { day: DayMenu }) {
  return (
    <>
      <div className="mb-4 mt-10 flex flex-row items-baseline justify-between">
        <div className="text-4xl font-semibold text-theme-normal">{day.title}</div>
        <div className="text-2xl font-semibold text-theme-low-contrast">{day.subTitle}</div>
      </div>
      {day.meals.map((meal) => (
        <Meal
          key={meal.id}
          meal={meal}
          date={day.date}
        />
      ))}
    </>
  );
}

function Meal({ meal, date }: { meal: MealType; date: string }) {
  return (
    <div className="mb-10">
      <div className="mb-2 flex flex-row items-baseline justify-between font-semibold text-theme-low-contrast">
        <div className="text-lg uppercase tracking-wide">{meal.title}</div>
        <div className="text-lg">{meal.timeOfDay || ""}</div>
      </div>
      <div>{meal.recipes.length > 0 && <MainRecipe recipe={meal.recipes[0]} />}</div>
      <div className="mt-2 flex flex-row justify-between align-top">
        <div>{meal.extraRecipesTitle || ""}</div>
        <Button>Start Cooking</Button>
      </div>
      <Link
        to={`/menu/${date}`}
        className="mt-4 block border-2 border-dotted border-theme-normal/50 p-2 text-center font-semibold text-theme-normal/50"
      >
        <PencilIcon className="inline w-4" /> Change Menu
      </Link>
    </div>
  );
}

function MainRecipe({ recipe }: { recipe: ScheduledRecipeType }) {
  return (
    <div>
      {recipe.imageUrl && (
        <img
          className="mb-2 aspect-[8/3] w-full rounded-md object-cover"
          src={recipe.imageUrl}
        />
      )}
      <div className="flex flex-row justify-between">
        <div className="text-xl font-bold text-theme-muted">{recipe.title}</div>
        {recipe.totalTime && <div>{recipe.totalTime}</div>}
      </div>
    </div>
  );
}

export function PlanPage() {
  usePageTitle("Plan");
  const days = useLoaderData() as DayMenu[];

  if (!days) {
    return "";
  }

  return (
    <div>
      {days.map((day) => (
        <Day
          key={day.date}
          day={day}
        />
      ))}
    </div>
  );
}
