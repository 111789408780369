import { PropsWithChildren, ReactElement, useContext } from "react";
import { Navigate } from "react-router";
import { Page } from "../types";
import { UserContext } from "../user-context";
import { TightHeading } from "./heading";
import { Logo } from "./logo";
import { StackedLayout } from "./stacked-layout";
import { MobileNav, Nav } from "./topnav";

export function ApplicationFrame({ children }: PropsWithChildren) {
  const user = useContext(UserContext);

  if (user.onboardingStatus == "start-cookbook") {
    return <Navigate to={Page.OnboardingAddCollections} />;
  }

  return (
    <StackedLayout
      navbar={Nav()}
      sidebar={MobileNav()}
    >
      {children}
    </StackedLayout>
  );
}

export function OnboardingFrame({ children }: PropsWithChildren) {
  return <>{children}</>;
}

type AuthPageHeaderProps = {
  heading: string;
  extra?: string | ReactElement;
};

export function AuthPageHeader({ heading, extra }: AuthPageHeaderProps) {
  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <Logo size="size-20" />
      <TightHeading
        level={2}
        className="mt-8"
      >
        {heading}
      </TightHeading>

      <div className="mt-2 text-sm leading-6 text-zinc-500">{extra}</div>
    </div>
  );
}

export function AuthFrame({ children }: PropsWithChildren) {
  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        {children}
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          alt="Food on a table"
          src="/images/signup-background-xl.webp"
          className="absolute inset-0 h-full w-full object-cover"
        />
      </div>
    </div>
  );
}
