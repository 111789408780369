import { Square3Stack3DIcon, TagIcon } from "@heroicons/react/20/solid";
import { Badge } from "../components/badge.tsx";

export function RecipeCourses({ courses }: { courses: string[] }) {
  if (courses.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-row flex-wrap gap-2 items-center">
      <Square3Stack3DIcon className="size-4" />
      {courses.map((course) => (
        <Badge
          key={course}
          color="yellow"
          className="font-bold"
        >
          {course}
        </Badge>
      ))}
    </div>
  );
}

export function RecipeTags({ tags }: { tags: string[] }) {
  if (tags.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-row flex-wrap gap-2 items-center">
      <TagIcon className="size-4" />
      {tags.map((tag) => (
        <Badge
          key={tag}
          color="green"
          className="font-bold"
        >
          {tag}
        </Badge>
      ))}
    </div>
  );
}
